<template>
  <div class="content_all">
    <div class="orderModel">
      <div class="box-card" style="flex: 1; margin-right: 14px; min-width: 420px">
        <div class="flex-jb flex-ac" style="padding-bottom: 20px;padding-top: 10px;">
          <div class="txt-333 font-w card-title"><img src="../image/icon_fzsjtj_.png" alt=""> 分转商家统计</div>
        </div>
        <div class="data-list">
          <div class="data-list-item">
            <div class="txt-333 fs-14 magt">
              <div class="color1">
                <span class="fs-30 font-w">
                  <countTo :startVal='0' :endVal='HomeDataObj.weekAddNum || 0' :duration='1500'></countTo>
                </span>
                <span>个</span>
              </div>
              <div>本周新增分转</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color:#ffba46;">
                <span class="fs-30 font-w">
                  <countTo :startVal='0' :endVal='HomeDataObj.monthAddNum || 0' :duration='1500'></countTo>
                </span>
                <span>个</span>
              </div>
              <div>本月新增分转</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color:#FF687B;">
                <span class="fs-30 font-w">
                  <countTo :startVal='0' :endVal='HomeDataObj.allNum || 0' :duration='1500'></countTo>
                </span>
                <span>个</span>
              </div>
              <div>生效分转商家</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="orderModel" style="width: 57%;">
      <div class="box-card" style="flex: 1; margin-right: 14px; min-width: 420px">
        <div class="flex-jb flex-ac" style="padding-bottom: 20px;">
          <div class="txt-333 font-w card-title"><img src="../image//icon_fzfhtj_.png" alt=""> 分转发货统计</div>
          <div class="txt-2 flex" style="margin-right: 20px;align-items: center;">
            <span>成交时间</span>
            <el-date-picker style="margin-left: 10px;" v-model="dateTime" :picker-options="pickerOptions" size="small"
              type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" :clearable="false" :default-time="['00:00:00', '23:59:59']" @change="dateTimeChange">
            </el-date-picker>
          </div>
        </div>
        <div class="data-list">
          <div class="data-list-item">
            <div class="txt-333 fs-14 magt">
              <div class="color1">
                <span class="fs-30 font-w">
                  <countTo :startVal='0' :endVal='foodsData.waitNum || 0' :duration='1500'></countTo>
                </span>
                <span>单</span>
              </div>
              <div style="display: flex;align-items: center;">
                分转待发货
                <el-tooltip class="item" effect="dark" content="当前分转商家与授权回收商成交的所有订单中，未发货订单数量" placement="top">
                  <img style="width: 13px;height: 13px;margin: 3px 0 0 5px;" src="../image/ico_ts_red.png" alt="">
                </el-tooltip>
              </div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color:#ffba46;">
                <span class="fs-30 font-w">
                  <countTo :startVal='0' :endVal='foodsData.timeoutNum || 0' :duration='1500'></countTo>
                </span>
                <span>单</span>
              </div>
              <div style="display: flex;align-items: center;">
                逾期5天未发货
                <el-tooltip class="item" effect="dark" content="分转到平台的订单超过5天未发货订单数量" placement="top">
                  <img style="width: 13px;height: 13px;margin: 3px 0 0 5px;" src="../image/ico_ts_red.png" alt="">
                </el-tooltip>
              </div>
              <!-- <div style="font-size: 12px;color: #999999;">（逾期待分转发货<span style="color: #FF687B;">100单</span> I 逾期未发货金额<span style="color: #FF687B;">1005元</span>）</div> -->
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color:#80C269;">
                <span class="fs-30 font-w">
                  <countTo :startVal='0' :endVal='foodsData.shippedNum || 0' :duration='1500'></countTo>
                </span>
                <span>单</span>
              </div>
              <div style="display: flex;align-items: center;">
                已发货
                <el-tooltip class="item" effect="dark" content="选择时间内，分转商家与授权回收商成交的订单中，通过线下或线上发货的订单" placement="top">
                  <img style="width: 13px;height: 13px;margin: 3px 0 0 5px;" src="../image/ico_ts_red.png" alt="">
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import _api from "@/utils/request";
export default {
  name: "orderModel",
  components: { countTo },
  data() {
    return {
      HomeDataObj: {},
      foodsData: {},
      fdParams: {
        "endTime": "",
        "startTime": ""
      },
      dateTime: [],
      day60: 100 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day60 ||
              time.getTime() < this.pickerMinDate - this.day60
            );
          }
          return false;
        },
      },
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getHomeData()
    this.getWaitSendOStatistics(2)
  },
  methods: {
    getHomeData() {
      _api.getDptmNumStatistics().then(res => {
        this.HomeDataObj = res.data
      }
      )
    },
    getWaitSendOStatistics(num) {
      const endtime = new Date().getTime()
      const startDate = this.getDay(-30)
      if (num === 1 && this.dateTime) {
        this.fdParams.startTime = this.dateTime[0]
        this.fdParams.endTime = this.dateTime[1]
      } else {
        console.log(endtime, startDate, new Date(startDate).getTime());
        this.fdParams.startTime = new Date(startDate).getTime()
        this.fdParams.endTime = endtime
        this.dateTime=[new Date(startDate),new Date()]
      }
      _api.getWaitSendOStatistics(this.fdParams).then(res => {
        this.foodsData = res.data
      }
      )
    },
    //获取当前日期
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "/" + tMonth + "/" + tDate;
    },
    //获取当前月份
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    // 时间区间查询
    dateTimeChange(e) {
      console.log(e);
      this.getWaitSendOStatistics(1)
    }
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.content_all {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.magt {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //  width: 300px;
}

.center_lin {
  width: 1px;
  height: 68px;
  background: #E0E0E0;
}

.orderModel {
  width: 40%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 20px;
  background-color: #fff;

  .modelbg {
    position: relative;
    width: 420px;
    height: 160px;
    background-size: 100% 100%;
    padding-top: 5px;
    box-sizing: border-box;
    background-repeat: no-repeat;

    .center {
      // position: absolute;
      // top: 10px;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // width: 100%;
      margin-left: 180px;

      .today {
        display: flex;
        align-items: center;

        // padding: 10px 0 0 0;
        div:first-child {
          // width: 22%;
          // height: 21px;
          font-size: 16px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          font-weight: bold;
          color: #333333;
          // line-height: 41px;
        }

        div:last-child {
          // width: 50%;
          // height: 35px;
          font-size: 46px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          // line-height: 55px;
        }
      }
    }
  }

  .NumberColor_First {
    color: #0981FF;
    font-size: 26px !important;
    display: flex;
  }

  .NumberColor_Sec {
    color: #FFAF29;
    font-size: 26px !important;
    display: flex;
  }

  .NumberColor_Third {
    color: #FF687B;
    font-size: 26px !important;
    display: flex;
  }

  .NumberColor_Four {
    color: #BB8EEF;
    font-size: 26px !important;
    display: flex;
  }

  // .firstBG{
  //     background-image: url('../../../../assets/images/home/bg_img_001.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  // .SecBG{
  //     background-image: url('../../../../assets/images/home/bg_img_002.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  // .ThirdBG{
  //     background-image: url('../../../../assets/images/home/bg_img_003.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  // .FourBG{
  //     background-image: url('../../../../assets/images/home/bg_img_004.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  .ml-10 {
    margin-left: 10px;
  }

  .box-card {
    background: #ffffff;
    border-radius: 4px;
    border-radius: 20px;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    /deep/.el-input__inner {
      width: 244px !important;
    }

    /deep/.el-range-separator {
      width: max-content !important;
    }
  }

  .card-title {
    padding-left: 16px;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }

  .date-click-item {
    margin-left: 30px;
    cursor: pointer;
  }

  .date-click-item:hover {
    text-decoration: underline;
    color: #20a0ff;
  }

  .date-click-item.active {
    color: #20a0ff;
    font-weight: 600;
  }

  .data-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 150px;
    padding: 20px 20px;
    border-top: 1px solid #EDEFFD;
    // flex: 1;
    flex-wrap: wrap;
  }

  .data-list-item {
    width: 100%;
    height: 86px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
  }

  .store-num {
    padding: 20px;
    min-height: 150px;
  }

  .store-num-item {
    height: 90px;
    width: 170px;
    background: #eff2fa;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .color1 {
    color: #20a0ff;
  }

  .color2 {
    color: #ff4949;
  }

  .color3 {
    color: #13ce66;
  }

  .color4 {
    color: #f7ba2a;
  }

  .font-w {
    font-weight: bold;
  }

  .txt-2 {
    color: #8492a6;
  }

  .txt-333 {
    color: #333333;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-30 {
    font-size: 30px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .flex {
    display: flex;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-ac {
    display: flex;
    align-items: center;
  }
}
</style>
