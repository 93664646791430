<template>
  <div class="class-list">
    <Topshow ref="numTop"></Topshow>
    <div class="center_form">
      <div class="cf_top">
        <div class="tf_one">
          <div class="line"></div>
          分转授权商家管理
        </div>
        <div>
          <el-button size="small" type="success" @click="addModel"
            >收货地址管理</el-button
          >
          <el-button size="small" type="warning" @click="actionFen"
            >分转发货设置</el-button
          >
          <el-button size="small" type="primary" @click="addmechrtClick"
            >+ 添加分转商家</el-button
          >
        </div>
      </div>
      <From />
    </div>
    <Table ref="tablechange" />
    <!--平台收货物流设置-->
    <el-dialog
      title="平台收货物流设置"
      :visible.sync="setDialogVisible"
      :close-on-click-modal="false"
      width="458px"
      @closed="rulerClosed"
    >
      <div class="verify-title"><span>商家信息</span></div>
      <div class="mb-20" style="font-size: 14px; color: #333333">
        <div class="mb-10">
          商家名称：<span style="color: #666666">{{
            rulesInfo.platformName || "--"
          }}</span>
        </div>
        <div>
          联系人：<span style="color: #666666">{{
            rulesInfo.contact || "--"
          }}</span
          ><span style="margin-left: 20px">联系电话：</span
          ><span style="color: #666666">{{ rulesInfo.phone || "--" }}</span>
        </div>
      </div>
      <div class="verify-title"><span>快递选择</span></div>
      <div
        class="flex mb-20"
        style="font-size: 14px; color: #333333; display: flex"
      >
        <div>
          首选：
          <el-select
            size="small"
            style="width: 60%"
            v-model="rulNew.firstExpressCode"
            placeholder="请选择快递类型"
            @change="selecteXPrice"
          >
            <el-option
              v-for="item in expressList"
              :key="item.expressCode"
              :label="item.expressName"
              :value="item.expressCode"
            ></el-option>
          </el-select>
        </div>
        <div>
          次选：<span style="color: #666666">{{rulNew.senkendcodename }}</span>
        </div>
      </div>
      <div class="verify-title"><span>发货价值设置</span></div>
      <div class="mb-20" style="font-size: 14px; color: #333333">
        <div style="display: flex; align-items: center">
          <span style="color: #666666">旧机总报价≥</span>
          <div style="width: 20%; margin: 0 5px">
            <el-input
              size="small"
              oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)"
              v-model="rulNew.minCostSend"
              placeholder="请输入"
            ></el-input>
          </div>
          <span style="color: #666666">元门店可发货一次</span>
        </div>
      </div>
      <div class="verify-title"><span>发货规则</span></div>
      <div class="mb-20" style="font-size: 14px; color: #333333">
        <div class="mb-10">
          发货订单：<span style="color: #666666"
            >当日发货前一日及以前成交订单</span
          >
        </div>
        <div class="mb-10 flex flex-as">
          <div>发货提醒规则：</div>
          <div style="color: #666666">
            <div>济南繁星回收门店端公众号消息推送</div>
            <div>
              <span style="color: #ff687b">次日8:30,9:30,10:30,16:30</span
              >发送消息提醒
            </div>
            <div>
              <span style="color: #0981ff">次月2号</span
              >再次推送上月未发货订单消息提醒
            </div>
          </div>
        </div>
        <div>
          发货提醒人员：<span style="color: #666666">门店店长、下单店员</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="setDialogVisible = false">关闭</el-button>
        <el-button type="primary" @click.native="sureSetplat">确认</el-button>
      </span>
    </el-dialog>
    <!-- 添加收货地址 -->
    <el-dialog
      :title="adFlag ? '添加收货地址' : '编辑收货地址'"
      :visible.sync="shipaddressDialogVisible"
      :close-on-click-modal="false"
      width="700px"
      @closed="sdClosed"
    >
      <div
        v-if="!adFlag"
        style="display: flex; align-items: center; margin-bottom: 20px"
      >
        <p style="color: #ff8080">
          说明：修改地址后，使用该地址分转的商家将默认同步更新
        </p>
      </div>
      <el-form
        :model="actioAdress"
        :rules="adRulesDialog"
        ref="adrressruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="收货人姓名" prop="contactName">
          <el-input
            type="text"
            v-model.trim="actioAdress.contactName"
            style="border: none"
            placeholder="请输入收货人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" prop="contactPhone">
          <el-input
            type="text"
            v-model.trim="actioAdress.contactPhone"
            oninput="this.value=value.replace(/[^0-9]/g,'')"
            style="border: none"
            placeholder="请输入收货人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="platformAddress">
          <el-input
            type="text"
            v-model.trim="actioAdress.platformAddress"
            style="border: none"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="门牌号" prop="houseAddress">
          <el-input
            type="text"
            v-model.trim="actioAdress.houseAddress"
            style="border: none"
            placeholder="请输入门牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="动态口令" prop="command">
          <el-input
            v-model="actioAdress.command"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="shipaddressDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="adressConfirmDialog('adrressruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加分转授权 -->
    <el-dialog
      title="添加分转授权"
      :visible.sync="sublicenseDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @closed="fenClosed"
    >
      <el-form
        :model="formData"
        label-position="left"
        :rules="formRulesDialog"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="选择分转商家" prop="companyId" label-width="110px">
          <el-select
            filterable
            style="width: 100%"
            v-model="formData.companyId"
            placeholder="请选择门店商家"
            @change="storeSlect"
          >
            <el-option
              v-for="item in optionsMerch"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="formData.companyId">
          <div class="center_show">
            <div>联系人：{{ relation.contactName }}</div>
            <div>联系电话：{{ relation.contactPhone }}</div>
          </div>
        </template>
        <div class="merchantId_class">
          <el-form-item label="授权回收商" required></el-form-item>
        </div>
        <template>
          <el-table
            max-height="400px"
            @selection-change="handleSelectionChange"
            :data="optionsSm"
            border
            v-loading="loading"
            style="width: 100%"
          >
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="merchantName" align="center" label="回收商">
              <template slot-scope="{ row }">
                <div>{{ row.merchantName }}</div>
                <el-tag type="danger" v-if="row.isMatrix">矩阵映射回收商</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="province" align="center" label="省市区">
              <template slot-scope="{ row }">
                <span v-if="row.province"
                  >{{ row.province || "" }}{{ row.city || ""
                  }}{{ row.region || "" }}</span
                >
                <span v-else>--</span>
                <span></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="warehouseId"
              align="center"
              label="分转仓库"
              width="170px"
            >
              <template slot-scope="{ row }">
                <el-select
                  v-if="!row.isMatrix"
                  v-model="row.warehouseId"
                  placeholder="请选择分转仓"
                >
                  <el-option
                    v-for="item in entrepotList"
                    :key="item.id"
                    :label="item.warehouseName"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <div
                  style="
                    text-decoration: underline;
                    color: #0981ff;
                    cursor: pointer;
                  "
                  v-else
                >
                  <span @click="seamatrixMiddleNum(row)">{{
                    row.matrixMiddleNum
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="waitSendOrderNum"
              align="center"
              label="当前待发货订单"
            >
            </el-table-column>
            <el-table-column
              prop="sendNoDeliveryNum"
              align="center"
              label="已发货待收货订单"
            >
            </el-table-column>
          </el-table>
        </template>
        <el-form-item label="设置收货地址" prop="price" label-width="105px">
          <span style="margin-left: -5px"
            >(若修改将同步更新该门店商家的全部的分转地址)</span
          >
        </el-form-item>
        <div
          class="ad_cstyle"
          style="margin-bottom: 30px"
          v-for="item in platAddList"
          :key="item.id"
        >
          <div class="adc_top">
            <div class="adc_line"></div>
            <span>{{ item.warehouseName }}</span>
            <div class="xiugai">
              <el-button @click="chansdreess(item)" type="text">修改</el-button>
            </div>
          </div>
          <p style="margin-bottom: 10px">
            {{ item.contactName }}-{{ item.contactPhone }}
          </p>
          <p>{{ item.platformAddress }}{{ item.houseAddress || " " }}</p>
        </div>
        <el-form-item label="生效时间" prop="effectiveTime">
          <div class="addFenTime">
            <el-date-picker
              :disabled="isImmediate"
              :picker-options="pickerOptions"
              v-model="formData.effectiveTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
            <div class="raido_inner" @click="addFenTimeChange">
              <div :class="isImmediate ? 'active_select' : ''"></div>
              <p>立即生效</p>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sublicenseDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="fenConfirmDialog('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 收货地址管理 -->
    <el-dialog
      title="收货地址管理"
      :visible.sync="adressDialogVisible"
      :close-on-click-modal="false"
      width="700px"
      @closed="problemClosed"
    >
      <div style="margin-bottom: 20px">
        <span>收货仓库：</span>
        <el-select
          clearable
          size="small"
          @change="warChange"
          v-model="warehouseId"
          placeholder="请选择仓库"
          filterable
        >
          <el-option
            v-for="item in entrepotList"
            :key="item.id"
            :label="item.warehouseName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <p style="color: #ff8080">说明：最多可添加3个收货地址</p>
        <el-button size="small" type="primary" @click="addAdrress"
          >+ 添加地址</el-button
        >
      </div>
      <div
        class="ad_cstyle actionstyle"
        v-for="(item, index) in adressList"
        :key="index"
      >
        <div
          class="adc_top"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="adc_line"></div>
            <span v-if="item.isDefault">默认地址</span>
            <span v-else>地址{{ index + 1 }}</span>
            <div class="xiugai" v-if="!item.isDefault">
              <el-button type="text" @click="bianArress(item)">编辑</el-button>
            </div>
          </div>
          <p
            v-if="!item.isDefault"
            style="color: #ff8080; font-size: 18px"
            @click="deteleAdrress(item)"
          >
            <i class="el-icon-delete"></i>
          </p>
        </div>
        <div style="margin-bottom: 10px">
          {{ item.contactName }}-{{ item.contactPhone }}
        </div>
        <div>{{ item.platformAddress }}{{ item.houseAddress || " " }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="adressDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择修改地址 -->
    <el-dialog
      title="选择修改地址"
      :visible.sync="actionDialogVisible"
      :close-on-click-modal="false"
      width="700px"
      @closed="cangeClosed"
    >
      <div
        class="ad_cstyle actionstyle displaghf"
        v-for="(item, index) in adressList"
        :key="index"
      >
        <div>
          <div class="adc_top">
            <div class="adc_line"></div>
            <span v-if="item.id == platAdres.id">当前地址</span>
            <span v-else>地址{{ index + 1 }}</span>
          </div>
          <p style="margin-bottom: 10px">
            {{ item.contactName }}-{{ item.contactPhone }}
          </p>
          <p>{{ item.platformAddress }}{{ item.houseAddress || " " }}</p>
        </div>
        <div
          class="im_actin"
          v-if="item.id !== platAdres.id"
          @click="adSlect(index, item)"
        >
          <img v-if="adreeId === index" src="./image/sel_duox_pre.png" alt="" />
          <img v-else src="./image/sel_duox.png" alt="" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="actionDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除提示 -->
    <el-dialog
      :title="enableTitle"
      :visible.sync="enableShow"
      :close-on-click-modal="false"
      width="400px"
      @closed="enableClosed"
    >
      <div class="enable_center">是否确认删除平台的收货地址？</div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          v-model="deAdreList.command"
          placeholder="请输入谷歌动态口令"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="enableShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="enableButLoading"
          @click.native="enableSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 启用分转提示 -->
    <el-dialog
      title="启用分转提示"
      :visible.sync="turnonShow"
      :close-on-click-modal="false"
      width="400px"
      @closed="enableClosed"
    >
      <div class="enable-tip">
        <div>
          说明：添加授权分转后，默认启用该分转授权，按照设置生效时间进行设置。
        </div>
        <div class="tip-sure">是否确认启用发货分转到平台？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="forommand"
          placeholder="请输入谷歌动态口令"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="turnonShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="enableButLoading"
          @click.native="turnonSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!--平台物流设置操作提示 -->
    <!--    -->
    <el-dialog
      title="操作提示"
      :visible.sync="delDialogVisible"
      :close-on-click-modal="false"
      width="400px"
    >
      <div class="enable-tip">
        <div>说明：修改后立刻生效。</div>
        <div class="tip-sure">是否确认修改平台收货的物流设置？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="delConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看矩阵报价回收商 -->
    <el-dialog
      title="查看矩阵报价回收商"
      :visible.sync="seaDialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="marxtableColumns"
        :data="marxDataList"
        :currentPage="marxpage.pageNum"
        :total="marxpage.total"
        @handleCurrentChange="marxCurrentChange"
      >
        <el-table-column label="省市区" slot="copyType" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.province"
              >{{ row.province || "" }}{{ row.city || ""
              }}{{ row.region || "" }}</span
            >
            <span v-else>--</span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
  </div>
</template>

<script>
import Topshow from "./compnents/orderModel.vue";
import From from "./Form";
import moment from "moment";
import Table from "./Table";
import _api from "@/utils/request";

export default {
  name: "class-list",
  components: {
    From,
    Table,
    Topshow,
  },
  data() {
    return {
      matrixCompanyId: "",
      marxpage: {
        pageNum: 1,
        pageSize: 10,
      },
      marxDataList: [],
      marxtableColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { slotName: "copyType" },
        { label: "设置分转仓", prop: "warehouseName" },
      ],
      seaDialogVisible: false,
      warehouseId: "",
      entrepotList: [],
      delDialogVisible: false,
      turnonShow: false,
      adreeId: "",
      optionsMerch: [],
      optionsSm: [],
      authorizationList: [],
      adFlag: false,
      deAdreList: {
        addressId: "",
        command: "",
      },
      forommand: "",
      actioAdress: {
        command: "",
        contactName: "",
        contactPhone: "",
        houseAddress: "",
        id: "",
        platformAddress: "",
      },
      adRulesDialog: {
        command: [
          {
            required: true,
            trigger: "blur",
            message: "请输入动态口令",
          },
        ],
        contactName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入收货人姓名",
          },
        ],
        contactPhone: [
          {
            required: true,
            trigger: "blur",
            message: "请输入收货人电话",
          },
        ],
        platformAddress: [
          {
            required: true,
            trigger: "blur",
            message: "请输入详细地址",
          },
        ],
      },
      enableButLoading: false,
      loading: false,
      enableTitle: "操作提示",
      enableShow: false,
      expressList: [
        {
          expressName: "",
        },
        {
          expressName: "",
        },
      ],
      adressList: [],
      rulesInfo: {},
      rulNew: {
        firstExpressCode: "shunfeng",
        minCostSend: "",
        senkendcodename:""
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      setDialogVisible: false,
      shipaddressDialogVisible: false,
      sublicenseDialogVisible: false,
      adressDialogVisible: false,
      actionDialogVisible: false,
      formRulesDialog: {
        companyId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择门店商家",
          },
        ],
        merchantId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择回收商家",
          },
        ],
        effectiveTime: [
          {
            required: true,
            trigger: "blur",
            message: "请选择生效时间",
          },
        ],
      },
      // 立即生效
      isImmediate: null,
      formData: {
        addressList: [],
        command: "",
        companyId: "",
        contactName: "",
        contactPhone: "",
        effectiveTime: "",
        id: "",
        merchants: [],
      },
      fenParams: {},
      relation: {
        contactName: "",
        contactPhone: "",
      },
      platAddList: [],
      platAdres: {
        contactName: "",
        contactPhone: "",
        houseAddress: "",
        id: "",
        platformAddress: "",
      },
    };
  },
  created() {
    this.getLogisticsConfigInfo();
    this.getplantAddress();
    this.getCompanySelectList();
  },
  watch: {
    isImmediate(v) {
      if (v) {
        this.formData.effectiveTime = moment().format("YYYY-MM-DD");
      }
    },
  },
  methods: {
    //   切换页
    marxCurrentChange(val) {
      if (val) {
        this.marxpage.pageNum = val;
      } else {
        this.marxpage.pageNum = 1;
      }
      const baseRequest = {
        companyId: this.matrixCompanyId || "",
        pageNum: this.marxpage.pageNum,
        pageSize: this.marxpage.pageSize,
      };
      this.loading = true;
      _api
        .unionMerchantList(baseRequest)
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.marxDataList = res.data.records;
            this.marxpage.total = res.data.total;
            this.marxpage.pageNum = res.data.current;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //查看矩阵合作商家列表
    seamatrixMiddleNum(row) {
      this.matrixCompanyId = row.matrixCompanyId;
      this.seaDialogVisible = true;
      this.marxCurrentChange(1);
    },
    handleSelectionChange(e) {
      console.log(e);
      this.authorizationList = e;
    },
    warChange(e) {
      console.log(e);
      this.getplantAddress();
    },
    //获取仓库
    getwarehouseSelectList() {
      _api.getwarehouseSelectList().then((res) => {
        this.entrepotList = res.data;
        if (res.data.length > 0) {
          this.warehouseId = res.data[0].id;
          this.getplantAddress();
        }
      });
    },
    //开启平台物流
    delConfirmDialog() {
      _api.saveConfigInfo(this.rulNew).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "保存成功！",
            type: "success",
          });
          this.setDialogVisible = false;
          this.delDialogVisible = false;
        }
      });
    },
    //查询门店商家下拉列表
    getCompanySelectList() {
      _api.getCompanySelectList().then((res) => {
        this.optionsMerch = res.data;
      });
    },
    //获取门店商平台收货地址
    getCompanyPlatformAddress(id) {
      _api.getCompanyPlatformAddress({ companyId: id }).then((res) => {
        this.platAddList = res.data || [];
      });
    },
    //选择门店请求合作商家
    storeSlect(e) {
      console.log(e);
      this.loading = true;
      _api.getptMerchantList({ companyId: e }).then((res) => {
        this.optionsSm = res.data;
        this.formData.merchants = [];
        this.loading = false;
      });
      this.getwarehouseSelectList();
      this.getCompanyPlatformAddress(e);
      this.optionsMerch.forEach((res) => {
        if (res.id === e) {
          this.relation = res;
        }
      });
    },
    // 生效时间
    addFenTimeChange() {
      this.isImmediate = !this.isImmediate;
    },
    //添加、编辑收货地址提交
    adressConfirmDialog(formName) {
      console.log(this.actioAdress);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api
            .savePlatformAddress({
              warehouseId: this.warehouseId,
              ...this.actioAdress,
            })
            .then((res) => {
              this.shipaddressDialogVisible = false;
              this.getplantAddress();
              this.$refs["adrressruleForm"].clearValidate();
              this.cleradreww();
            });
        } else {
          return false;
        }
      });
    },
    //分转授权
    fenConfirmDialog(formName) {
      console.log(this.formData, this.platAddList);
      if (this.authorizationList.length == 0) {
        return this.$message.error("请选择授权回收商！");
      } else {
        if (this.authorizationList.some((item) => !item.warehouseId&&!item.isMatrix)) {
          return this.$message.error("请选择授权回收商的分转仓！");
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.addressList = this.platAddList.map((item) => {
            return {
              addressId: item.id,
              warehouseId: item.warehouseId,
            };
          });
          this.formData.merchants = this.authorizationList.map((item) => {
            return {
              merchantId: item.merchantId,
              warehouseId: item.warehouseId,
            };
          });
          this.formData.contactName = this.relation.contactName;
          this.formData.contactPhone = this.relation.contactPhone;
          this.turnonShow = true;
        } else {
          return false;
        }
      });
    },
    chansdreess(data) {
      this.platAdres = data;
      _api.queryFormAddress({ warehouseId: data.warehouseId }).then((res) => {
        this.adressList = res.data;
        this.actionDialogVisible = true;
      });
    },
    handleConfirmDialog() {
      console.log(this.adreeId);
      if (this.adreeId !== "") {
        //修改仓库地址
        this.platAddList.forEach((item, index) => {
          if (this.platAdres.warehouseId == item.warehouseId) {
            this.platAddList[index] = JSON.parse(
              JSON.stringify(this.adressList[this.adreeId])
            );
          }
        });
      }
      this.actionDialogVisible = false;
    },
    //选择修改地址
    adSlect(index, data) {
      console.log(index, data);
      this.adreeId = index;
    },
    //清空地址
    cleradreww() {
      this.actioAdress = {
        command: "",
        contactName: "",
        contactPhone: "",
        houseAddress: "",
        id: "",
        platformAddress: "",
      };
    },
    //查询平台商家地址
    getplantAddress() {
      _api.queryFormAddress({ warehouseId: this.warehouseId }).then((res) => {
        this.adressList = res.data;
      });
    },
    //删除收货地址
    deteleAdrress(data) {
      console.log(data);
      this.deAdreList.addressId = data.id;
      this.enableShow = true;
    },
    //创建分转
    turnonSubmit() {
      console.log(this.formData);
      this.formData.command = this.forommand;
      this.fenParams = JSON.parse(JSON.stringify(this.formData));
      this.fenParams.effectiveTime = moment(
        this.fenParams.effectiveTime
      ).format("x");
      this.fenParams.isImmediate = this.isImmediate;
      if (this.isImmediate) {
        this.fenParams.effectiveTime = null;
      }
      this.enableButLoading = true;
      _api
        .saveMiddle(this.fenParams)
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "创建成功！",
              type: "success",
            });
            this.forommand = "";
            this.$refs.numTop.getHomeData();
            this.$refs.tablechange.handleCurrentChange();
            this.turnonShow = false;
            this.sublicenseDialogVisible = false;
          }
          this.enableButLoading = false;
        })
        .catch((err) => {
          this.enableButLoading = false;
        });
    },
    //删除确认
    enableSubmit() {
      console.log(this.deAdreList);
      _api.deplantAddress(this.deAdreList).then((res) => {
        this.deAdreList = {
          addressId: "",
          command: "",
        };
        this.$message({
          message: "删除成功！",
          type: "success",
        });
        this.getplantAddress();
        this.enableShow = false;
      });
    },
    sdClosed() {
      this.$refs["adrressruleForm"].clearValidate();
    },
    //关闭删除
    enableClosed() {
      this.deAdreList.command = "";
      this.formData.command = "";
      this.forommand = "";
      console.log("0000");
    },
    problemClosed() {},
    //关闭选择修改地址
    cangeClosed() {
      this.adreeId = "";
      this.platAdres = {};
      console.log(this.adreeId);
    },
    //快递选择
    selecteXPrice(){
       console.log(this.rulNew.firstExpressCode);
       if(this.rulNew.firstExpressCode=="jd"){
        this.rulNew.senkendcodename="顺丰物流"
       }else{
        this.rulNew.senkendcodename="京东物流"
       }
    },
    rulerClosed() {
      this.rulNew = {
        firstExpressCode: "shunfeng",
        minCostSend: "",
        senkendcodename:""
      };
    },
    fenClosed() {
      this.formData = {
        companyId: "",
        merchants: [],
        addressList: [],
        effectiveTime: "",
      };
      this.adressList = [];
      this.platAddList = [];
      this.optionsSm = [];
    },
    //收货地址管理
    addModel() {
      this.getwarehouseSelectList();
      this.adressDialogVisible = true;
    },
    //
    actionFen() {
      this.getLogisticsConfigInfo();
      this.setDialogVisible = true;
    },
    addmechrtClick() {
      // 去除默认选择
      this.isImmediate = false;
      this.getCompanySelectList();
      this.sublicenseDialogVisible = true;
    },
    //编辑地址
    bianArress(data) {
      this.actioAdress = data;
      this.adFlag = false;
      this.shipaddressDialogVisible = true;
    },
    //添加地址
    addAdrress() {
      if (this.adressList.length < 3) {
        this.cleradreww();
        this.adFlag = true;
        this.shipaddressDialogVisible = true;
      } else {
        this.$message({
          message: "最多可添加3个收货地址",
          type: "warning",
        });
      }
    },
    sureSetplat() {
      console.log(this.rulNew);
      if (!this.rulNew.minCostSend) {
        this.$message.error("请填写发货价值");
        return;
      }
      this.delDialogVisible = true;
    },
    //获取物流规则
    getLogisticsConfigInfo() {
      _api.getConfigInfo().then((res) => {
        if (res.code === 1) {
          this.rulesInfo = res.data;
          this.rulNew.minCostSend = this.rulesInfo.minCostSend || "100";
          res.data.expressList.forEach((item) => {
            if (item.isFirst) {
              this.rulNew.firstExpressCode = item.expressCode;
            }else{
              this.rulNew.senkendcodename=item.expressName
            }
          });
          this.expressList = res.data.expressList;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.ad_cstyle {
  width: 100%;
  padding: 15px;
  min-height: 110px;
  background: #f5f6fa;
  border-radius: 14px;
  margin-bottom: 15px;
  margin-top: -20px;

  .adc_top {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .adc_line {
      width: 3px;
      height: 13px;
      background: #0981ff;
      border-radius: 2px;
      margin-right: 5px;
    }

    .xiugai {
      /deep/ .el-button {
        text-decoration: underline;
        padding: 5px 0 5px 10px;
      }
    }
  }
}

.actionstyle {
  margin: 20px 0;
}

.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.displaghf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.im_actin {
  img {
    width: 15px;
    height: 15px;
  }
}

.enable_center {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.center_show {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 0 20px 20px;
}

.verify-title {
  color: #0981ff;
  line-height: 16px;
  font-size: 16px;
  padding-left: 10px;
  border-left: 3px solid;
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.font-w {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex-as {
  display: flex;
  align-items: flex-start;
}

.center_form {
  background-color: #fff;
  padding: 20px 10px 10px 10px;

  .cf_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .tf_one {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: rgb(51, 51, 51);
      margin-right: 20px;

      .line {
        width: 4px;
        height: 16px;
        background: #4966d2;
        margin-bottom: 0px;
        margin-right: 10px;
      }
    }
  }
}

.addFenTime {
  width: 100%;
  display: flex;
  align-items: center;

  > div:first-of-type {
    width: 60% !important;
    margin-right: 20px;
  }

  > div:last-of-type {
    flex: auto;
  }
}

.raido_inner {
  display: flex;
  align-items: center;
  cursor: pointer;

  > div {
    border: 1px solid #0981ff;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }

  > p {
    font-size: 14px;
    margin-left: 10px;
  }

  .active_select {
    border-color: #0981ff;
  }

  .active_select::after {
    background: #0981ff;
  }
}

.merchantId_class {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
</style>
