<template>
  <div class="GlobalFormDemo">
    <GlobalForm :init-data="initData" :form-item-list="formItemList" :inline="true" :labelWidth="60" round :needBtnLoading="true"
      @handleConfirm="handleConfirm" confirmBtnName="查询">
      <!-- <el-form-item class="el_from" label="出库仓库" slot="Incomingwarehouse" labelWidth="80px" style="margin:0">
          <el-select size="small"  v-model="warehouseId" placeholder="请选择仓库" filterable
              clearable>
              <el-option v-for="item in entrepotList" :key="item.id" :label="item.warehouseName"
                :value="item.id"></el-option>
            </el-select>
        </el-form-item> -->
      <div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
        <el-button icon="el-icon-download" size="small" type="success" :loading="exportLoading" @click="exportExcel">导出Excel</el-button>
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import moment from "moment";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      warehouseId:"",
      entrepotList:[],
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      dialogVisible: false,
      orderDialogVisible: false,
      delDialogVisible: false,
      ImageDelDialogVisible: false,
      loading: false,
      dialogPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      imgShow: false,
      setHideOrderDialogVisible: false,

      dataList: [],
      formItemList: [
    {slotName:"Incomingwarehouse"},
        {
          lableWidth:"70px",
          key: "companyName",
          type: "input",
          labelName: "门店商家",
          placeholder: "输入门店商家",
        },
        {
          lableWidth:"100px",
          key: "merchantName",
          type: "input",
          labelName: "授权回收商",
          placeholder: "输入授权回收商",
        },
        {
          lableWidth: "80px",
          key: "entryTime",
          type: "pickerOptions",
          labelName: "生效时间",
          placeholder: "请选择生效时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        {
          key: "effectiveStatus",
          type: "select",
          lableWidth: "70px",
          labelName: "生效状态",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "00",
              label: "未生效",
            },
            {
              value: "01",
              label: "生效中",
            },
            {
              value: "02",
              label: "已禁用",
            },
          ],
        },
       
      ],
      formData: {
        total: null,
        showTime: "",
      },
      canDivide: 0, //当前可分配评论数量
      noDivide: 0, //当前未分配评论数量
      formRules: {
        total: [
          {
            required: true,
            trigger: "blur",
            message: "请输入分配数量",
          },
        ],
        showTime: [
          {
            required: true,
            message: "请选择分配时间",
            trigger: "change",
          },
        ],
      },
      orderFormData: {
        ackEndTime: "", //订单成交结束时间【时间戳】
        ackStartTime: "", //	订单成交开始时间【时间戳】
        endFinalPrice: "", //结束价格区间
        startFinalPrice: "", //	开始价格区间
        brandNo: "", //品牌编号
        phoneName: "", //机型名称
      },
      orderTime: null,
      imgShowIndex: 0,
      DialogImgList: [],
      currOrderNo: "",
      oldBrandSelectList: [],
      orderList: [], //导入订单至评论库
      storageArr: [], // 存取翻页前的数据
      storageArr2: [], // 存取翻页前的数据
      selectOrderList: [], //回显选择打钩
      exportLoading: false,
    };
  },
  created() {
    let startTime = moment()
      .day(moment().day() - 6)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.orderTime = [startTime, endTime];
    this.orderFormData.ackStartTime = new Date(startTime).getTime();
    this.orderFormData.ackEndTime = new Date(endTime).getTime();
    this.handleConfirm(this.initData);
    // this.getoldBrandSelectList();
  },
  watch: {},
  methods: {
     //获取仓库
     getwarehouseSelectList() {
      _api.getwarehouseSelectList().then(res => {
        this.entrepotList = res.data
      })
    },
    //导出Excel
    exportExcel(e) {
      console.log(this.SeachParams);
      this.exportLoading = true;
      _api.middleExcel(this.SeachParams).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "分转授权商家明细.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      }).finally(() => {
            this.exportLoading = false;
          });
    },
    handleConfirm(data,cd) {
      let SeachParams = {};
      console.log(data);
      if (data) {
        SeachParams = {
          companyName: data.companyName,
          effectiveStatus: data.effectiveStatus,
          merchantName: data.merchantName,
          warehouseId:this.warehouseId,
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }
      this.SeachParams=SeachParams
      console.log("提交了form", SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 获取品牌下拉
    getoldBrandSelectList() {
      _api.oldBrandSelectList().then((res) => {
        this.oldBrandSelectList = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .price_input {
    width: 195px;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #e4ecfd;
    box-sizing: border-box;
    color: #666666;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 30px;

    >input {
      outline: 0;
      width: 70px;
      text-align: center;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flexwarp {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }

  .flex-je {
    display: flex;
    justify-content: flex-end;
  }

  .label {
    white-space: nowrap;
    margin-right: 10px;
    width: 56px;
  }

  .images-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .images-box>span {
    color: #0981ff;
    cursor: pointer;
  }

  .text-down {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }

  .img-box {
    margin-bottom: 20px;

    // 图片
    .imgs-num {
      text-align: center;
      margin: 5px 0;
      font-weight: bold;
    }

    .img-tips-box {
      display: flex;
      height: 30px;
      width: max-content;
      border: 1px solid #0981ff;

      .img-tips {
        width: 80px;
        text-align: center;
        line-height: 30px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .img-tips-default {
        background: #fff;
        color: #0981ff;
      }

      .img-tips-choose {
        background: #0981ff;
        color: #fff;
      }
    }

    // 列表
  }

  /deep/input::-webkit-inner-spin-button {
    display: none !important;
  }
}</style>
