<template>
  <div class="GlobalTable">
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="联系人" slot="name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.contactName + "-" + row.contactPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="生效状态" slot="isflagable" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.effectiveStatus == '00' ? "未生效" : row.effectiveStatus == '01' ? "生效中" : "已禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合作状态" slot="flagstatus" align="center">

        <template slot-scope="{ row }">
          <span>
            {{
      row.applyStatus == "00"
        ? "邀请待同意"
        : row.applyStatus == "01"
          ? "合作中"
          : row.applyStatus == "02"
            ? "邀请未通过"
            : "取消合作"
    }}
            {{
        row.companyIsEnable == "02" &&
          row.applyStatus != "00" &&
          row.applyStatus != "02"
          ? "(关闭报价)"
          : ""
      }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="添加人" slot="tanName" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.createManager + "-" + row.createManagerAcc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="待发货旧机" slot="waitSendNum" align="center" width="100">

        <template slot-scope="{ row }">
          <span style="cursor:pointer;color: rgb(9, 129, 255);text-decoration: underline;" @click="shipMachine(row)">{{
      row.waitSendNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分转仓库" slot="fenwarehouse" align="center" width="100">
        <template slot-scope="{ row }">
          <span>{{ row.warehouseName }}</span>
          <p v-if="row.isMatrix" style="cursor:pointer;color: rgb(9, 129, 255);text-decoration: underline;" @click="seamatrix(row)"> 查看</p>
        </template>
      </el-table-column>
      <el-table-column label="启用/禁用" slot="QCswitch" align="center" width="110">

        <template slot-scope="{ row }">
          <div class="switcStle">
            <el-switch @change="switchInsideBiz(row)" v-model="row.isEnable">
            </el-switch>
            <div @click="transfeAction(row)" style="cursor: pointer;color: #409EFF;font-size: 14px;"
              v-if="row.isEnable">
              <u>编辑</u>
            </div>
            <div style="cursor: pointer;color: #A1A4AF;font-size: 14px;" v-else>
              <u>编辑</u>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="平台自动发货" slot="expressSwitch" align="center" width="110">

        <template slot-scope="{ row }">
          <el-switch @change="expressChange(row)" v-model="row.openPlatformCallExpress">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column width="300" label="操作" slot="operation" align="center">

        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" :disabled="row.isDivide" round
            @click="problemClick(row)">查看订单</el-button>
          <el-button size="mini" type="success" :disabled="row.isDivide" round
            @click="subrevolutionClick(row)">分转订单</el-button>
          <el-button v-if="!row.isDivide" size="mini" type="danger" round @click="delClick(row)">催发货</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 待发货旧机 -->
    <el-dialog title="待发货旧机" :visible.sync="spDialogVisible" :close-on-click-modal="false" width="1200px"
      @closed="spClosed">
      <GlobalForm :init-data="machineData" :form-item-list="machineItemList" :inline="true" :labelWidth="60" round
        @handleConfirm="machinementConfirm" confirmBtnName="查询">
        <el-form-item label-width="70px" class="el_from" label="门店名称" slot="storeId" style="margin:0">
          <el-select v-model="storeId" clearable filterable size="small" id="select" placeholder="请选择或输入门店名称">
            <el-option v-for="item in storeList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" slot="staffPhone" style="margin:0">
          <el-input clearable placeholder="请输入手机号" v-model="staffPhone">
          </el-input>
        </el-form-item>
        <el-form-item label="姓名" slot="staffName" style="margin:0">
          <el-input clearable placeholder="请输入姓名" v-model="staffName">
          </el-input>
        </el-form-item>
      </GlobalForm>
      <GlobalTable ref="GlobalTable" :pageSize="machinepage.pageSize" v-loading="mcloading"
        :columns="machinetableColumns" :data="this.machineDataList" :currentPage="machinepage.pageNum"
        :total="machinepage.total" @handleCurrentChange="machineCurrentChange">
        <el-table-column label="订单编号" slot="orderNo" align="center">

          <template slot-scope="{ row }">
            <span style="text-decoration: underline;color: rgb(9, 129, 255);;cursor: pointer;">{{ row.orderNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="门店" slot="storeName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.storeName || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单人" slot="actionplople" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.staffName }}-{{ row.staffPhone }}</span>
            <div :style="row.staffType == '03' && row.isStaffSub == true
      ? 'cursor: pointer'
      : ''
      ">
              <el-tag type="success" size="mini" effect="dark" @click="
      row.staffType == '03' && row.isStaffSub == true
        ? seeSubInfo(row)
        : ''
      ">{{
      !row.staffType || row.staffType == null
        ? "--"
        : row.isMajor == true
          ? "创建人"
          : row.staffType == "01"
            ? "子账号"
            : row.staffType == "02"
              ? "总监"
              : row.staffType == "03" && row.isStaffSub == true
                ? "店长-从账号"
                : row.staffType == "03"
                  ? "店长"
                  : "店员"
    }}
              </el-tag>
            </div>

          </template>
        </el-table-column>
        <el-table-column label="旧机名称" slot="phoneName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.phoneName || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单人" slot="name" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.contactName + "-" + row.contactPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="成交天数" slot="afterAckDays" align="center" width="80">

          <template slot-scope="{ row }">
            <span>{{ row.afterAckDays }}天</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">

          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="OffdeliveryClick(row)">线下发货</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="spDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 门店催发货 -->
    <el-dialog title="门店催发货" :visible.sync="deliveryDialogVisible" :close-on-click-modal="false" width="1000px"
      @closed="spClosed">
      <GlobalForm :init-data="machineData" :form-item-list="machineItemList" :inline="true" :labelWidth="60" round
        @handleConfirm="shipmentConfirm" confirmBtnName="查询">
        <el-form-item label-width="70px" class="el_from" label="门店名称" slot="storeId" style="margin:0">
          <el-select v-model="storeId" clearable filterable size="small" id="select" placeholder="请选择或输入门店名称">
            <el-option v-for="item in storeList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" slot="staffPhone" style="margin:0">
          <el-input clearable placeholder="请输入手机号" v-model="staffPhone">
          </el-input>
        </el-form-item>
        <el-form-item label="姓名" slot="staffName" style="margin:0">
          <el-input clearable placeholder="请输入姓名" v-model="staffName">
          </el-input>
        </el-form-item>
      </GlobalForm>
      <div style="margin-bottom: 10px;">
        <el-button v-if="storeIdsList.length < 2" type="info" size="small">批量催发货</el-button>
        <el-button v-else type="danger" size="small" @click="cuClick(1)">批量催发货</el-button>
      </div>
      <GlobalTable @handleSelect="handleSelect" :isSelection="true" ref="GlobalTable" v-loading="urloading"
        :columns="urtableColumns" :data="urDataList" :maxHeight="700" :isPagination="false"
        @handleCurrentChange="UrgeCurrentChange">
        <el-table-column label="门店" slot="storeName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.storeName || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="门店店长" slot="smStaffName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.smStaffName }}-{{ row.smStaffPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="待发货旧机" slot="waitNum" align="center">

          <template slot-scope="{ row }">
            <span style="color: rgb(9, 129, 255);;cursor: pointer;">{{ row.waitNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="旧机价值" slot="waitAmount" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.waitAmount || "--" }}元</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">

          <template slot-scope="{ row }">
            <el-button size="mini" type="danger" round @click="cuClick(row)">催发货</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="deliveryDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 禁用/启用分转提示 -->
    <el-dialog :title='enableTitle' :visible.sync="enableShow" :close-on-click-modal="false" width="400px"
      @closed="insideClosed">
      <div class="enable-tip">
        <div>{{ insideType ? '说明：添加授权分转后，默认启用该分转授权，按照设置生效时间进行设置。' : '说明：禁用后，分转授权失效，该商家与授权回收商成交的订单默认发往回收商。' }}</div>
        <div class="tip-sure">{{ insideType ? '是否确认启用发货分转到平台？' : '是否确认禁用发货分转到平台？' }}</div>
        <div v-if="!insideType && waitSendNum > 0" class="phone_redynum">
          当前待发货数量共<span>{{ waitSendNum }}台</span>，禁用后将改为<span>发往回收商</span></div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="insideClosed">取消</el-button>
        <el-button type="primary" :loading="enableButLoading" @click.native="insideSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑分转授权 -->
    <el-dialog title="编辑分转授权" :visible.sync="sublicenseDialogVisible" :close-on-click-modal="false" width="700px"
      @closed="fenClosed">
      <el-form :model="formData" label-position="left" :rules="formRulesDialog" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="选择分转商家" required prop="companyName" label-width="110px">
          <el-input disabled v-model="formData.companyName" placeholder="请输入内容"></el-input>
        </el-form-item>

        <template>
          <div class="center_show">
            <div>联系人：{{ formData.contactName }}</div>
            <div>联系电话：{{ formData.contactPhone }}</div>
          </div>
        </template>
        <div class="ware_hous">
          <el-form-item label="分转回收商" required prop="merchantName">
          </el-form-item>
          <div style="padding-left: 20px;">
            <el-form-item label="授权回收商" prop="merchantName">
              <span>{{ formData.merchantName }}</span>
              <!-- <el-input disabled v-model="formData.merchantName" placeholder="请输入内容"></el-input> -->
              <div class="biaoji_gb"><el-tag type="danger" v-if="formData.isMatrix">矩阵映射回收商</el-tag></div>
            </el-form-item>
            <el-form-item label="当前分转仓" label-width="105px">
             <div v-if="!formData.isMatrix">
              <span style="margin-left: -5px;">{{ formData.warehouseName }}</span>
              <span class="update_warehouse" @click="modification">修改</span>
             </div>
             <span v-else>该矩阵共<span style="color: red;cursor: pointer;text-decoration: underline;" @click="seamatrixMiddleNum()">{{formData.matrixMiddleNum}}家</span>报价商家</span>
            </el-form-item>
          </div>
        </div>
        <el-form-item required label="设置收货地址" label-width="110px">
          <span style="margin-left: -5px;">(若修改将同步更新该门店商家的全部的分转地址)</span>
        </el-form-item>
        <div style="margin-top: -15px;">
          <div class="ad_cstyle" style="margin-bottom: 20px;" v-for="(item, index) in formData.addressList"
            :key="index">
            <div class="adc_top">
              <div class="adc_line"></div>
              <span>{{ item.warehouseName }}</span>
              <div class="xiugai">
                <el-button @click="chansdreess(item)" type="text">修改</el-button>
              </div>
            </div>
            <p style="margin-bottom: 10px;">{{ item.contactName }}-{{ item.contactPhone }}</p>
            <p>{{ item.platformAddress }}{{ item.houseAddress || " " }}</p>
          </div>
        </div>
        <el-form-item label="生效时间" prop="effectiveTime">
          <div v-if="!isEnableAction">
            <el-date-picker :disabled="efftimeFlag" :picker-options="pickerOptions" v-model="formData.effectiveTime"
              type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="addFenTime" v-if="isEnableAction && insideType">
            <el-date-picker :disabled="isImmediate" :picker-options="pickerOptions" v-model="formData.effectiveTime"
              type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <div class="raido_inner" @click="addFenTimeChange">
              <div :class="isImmediate ? 'active_select' : ''"></div>
              <p>立即生效</p>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sublicenseDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="fenConfirmDialog('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!--修改分转仓操作提示 -->
    <el-dialog title="修改分转仓" @closed="rollClosed" :visible.sync="rolloverDialogVisible" :close-on-click-modal="false"
      width="600px">
      <div>
        <div class="enable-tip">说明：修改分转仓库后，待发货订单将自动修改为新的分转仓，已发货订单如取消发货也将改为新的分转仓。</div>
        <el-form ref="form" :model="rollform" label-width="80px">
          <el-form-item label="当前分转仓:" label-width="90px">
            <span>{{ formData.warehouseName }}</span>
          </el-form-item>
          <el-form-item label="修改后分转仓:" label-width="100px">
            <el-select clearable v-model="rollform.region" placeholder="请选择分转仓">
              <el-option v-for="item in entrepotList" :key="item.id" :label="item.warehouseName"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="原分转仓遗留订单统计:" label-width="158px">
            <span>当前共有{{ formData.waitSendOrderNum }}台待发货订单，{{ formData.sendNoDeliveryNum }}个待收货订单</span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="rolloverDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="rolloverConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!--催发货操作提示 -->
    <el-dialog title="操作提示" :visible.sync="delDialogVisible" :close-on-click-modal="false" width="400px">
      <div class="enable-tip">
        <div>说明：催发货以微信公众号提醒方式，向门店店长、下单店员发送待发货消息提醒。</div>
        <div class="tip-sure">是否确认催发货？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="delConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 线下发货提示 -->
    <el-dialog title='启用分转提示' :visible.sync="turnonShow" :close-on-click-modal="false" width="450px"
      @closed="enableClosed">
      <div class="enable-tip">
        <div>说明：标记线下发货需向店员确认发货快递单号，并填写在下方。标记后，待发货不再统计该订单。</div>
        <div class="tip-sure">是否确认该订单已线下发货？</div>
      </div>
      <div class="command">
        <div style="width: 130px;">线下快递单号：</div>
        <el-input v-model="OfflineDelivery" placeholder="请输入线下发货的快递单号"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="turnonShow = false">取消</el-button>
        <el-button type="primary" :loading="enableButLoading" @click.native="offlineSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 选择修改地址 -->
    <el-dialog title="选择修改地址" :visible.sync="actionDialogVisible" :close-on-click-modal="false" width="700px"
      @closed="cangeClosed">
      <div class="ad_cstyle actionstyle displaghf" v-for="(item, index) in adressList" :key="index">
        <div>
          <div class="adc_top">
            <div class="adc_line"></div>
            <span v-if="item.id == platAdres.id">当前地址</span>
            <span v-else>地址{{ index + 1 }}</span>
          </div>
          <p style="margin-bottom: 10px;">{{ item.contactName }}-{{ item.contactPhone }}</p>
          <p>{{ item.platformAddress }}{{ item.houseAddress || " " }}</p>
        </div>
        <div class="im_actin" v-if="item.id !== platAdres.id" @click="adSlect(index, item)">
          <img v-if="adreeId === index" src="./image/sel_duox_pre.png" alt="">
          <img v-else src="./image/sel_duox.png" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="actionDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="acConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <DialogAction v-bind="expressProps" @config="expressConfig"
      @close="expressProps.show = false; handleCurrentChange()">

    </DialogAction>
     <!-- 查看矩阵报价回收商 -->
     <el-dialog
      title="查看矩阵报价回收商"
      :visible.sync="seaDialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="marxtableColumns"
        :data="marxDataList"
        :currentPage="marxpage.pageNum"
        :total="marxpage.total"
        @handleCurrentChange="marxCurrentChange"
      >
        <el-table-column label="省市区" slot="copyType" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.province"
              >{{ row.province || "" }}{{ row.city || ""
              }}{{ row.region || "" }}</span
            >
            <span v-else>--</span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from 'moment'
import DialogAction from "@/components/common/DialogAction.vue";
export default {
  components: {
    DialogAction
  },
  name: "Table",
  data() {
    return {
      matrixCompanyId: "",
      marxpage: {
        pageNum: 1,
        pageSize: 10,
      },
      marxDataList: [],
      marxtableColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { slotName: "copyType" },
        { label: "设置分转仓", prop: "warehouseName" },
      ],
      seaDialogVisible: false,
      warehouseId: "",
      entrepotList: [],
      waitSendNum: 0,
      // 当前启用禁用状态
      isEnableAction: null,
      // 快递prop
      expressProps: {
        title: "",
        isTip: true,
        tipText: "",
        show: false,
      },
      OfflineDelivery: "",
      turnonShow: false,
      efftimeFlag: false,//是否禁用生效时间
      adreeId: "",
      adressList: [],
      storeList: [],
      storeId: "",
      staffPhone: "",
      staffName: "",
      enableButLoading: false,
      command: "",
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      enableTitle: "启用分转提示",
      enableShow: false,
      seachDataList: [],
      machineDataList: [],
      urDataList: [],
      initData: null,
      machineData: null,
      imgShowIndex: 0,
      problemDialogVisible: false,
      sublicenseDialogVisible: false,
      rolloverDialogVisible: false,
      rollform: {
        region: ""
      },
      deliveryDialogVisible: false,
      shipaddressDialogVisible: false,
      spDialogVisible: false,
      delDialogVisible: false,
      imgShow: false,
      DialogImgList: [],
      machineItemList: [
        { slotName: 'storeId' },
        { slotName: 'staffPhone' },
        { slotName: 'staffName' },
      ],
      formItemList: [
        {
          key: "storeId",
          type: "selectFilterable",
          lableWidth: "70px",
          labelName: "门店名称",
          clear: true,
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "已分配",
            },
            {
              value: false,
              label: "未分配",
            },
          ],
        },
        {
          lableWidth: "60px",
          key: "staffPhone",
          type: "input",
          labelName: "手机号",
          placeholder: "输入手机号",
        },
        {
          lableWidth: "45px",
          key: "staffName",
          type: "input",
          labelName: "姓名",
          placeholder: "输入姓名",
        },
      ],
      formRules: {
        isEnable: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
        remark: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
      },
      formRulesDialog: {
        companyId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择门店商家",
          },
        ],
        merchantId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择回收商家",
          },
        ],
        effectiveTime: [
          {
            required: true,
            trigger: "blur",
            message: "请选择生效时间",
          },
        ],
      },
      formData: {
        addressId: "",
        command: "",
        companyName: "",
        companyId: "",
        merchantName: "",
        contactName: "",
        contactPhone: "",
        effectiveTime: "",
        id: "",
        merchantId: '',//编辑时
        addressList: []
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      machinepage: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      urparams: {
        "middleId": "",
        "pageNum": null,
        "pageSize": null,
        "staffName": "",
        "staffPhone": "",
        "storeId": ""
      },
      machineparams: {
        "middleId": "",
        "pageNum": 1,
        "pageSize": 5,
        "staffName": "",
        "staffPhone": "",
        "storeId": ""
      },
      insideType: false,
      machinetableColumns: [
        { slotName: "orderNo" },
        { slotName: "storeName" },
        { slotName: "actionplople" },
        { slotName: "phoneName" },
        { label: "旧机串号", prop: "imei" },
        { label: "成交时间", prop: "ackTime" },
        { slotName: "afterAckDays" },
        { slotName: "operation" },
      ],
      urtableColumns: [
        { slotName: "storeName" },
        { slotName: "smStaffName" },
        { slotName: "waitNum" },
        { slotName: "waitAmount" },
        { slotName: "operation" },
      ],
      tableColumns: [
        { label: "门店商家", prop: "companyName" },
        { slotName: "name" },
        { label: "分转授权回收商", prop: "merchantName" },
        { slotName: "isflagable" },
        { slotName: "flagstatus" },
        { slotName: "tanName" },
        { label: "添加时间", prop: "createTime" },
        { label: "生效时间", prop: "effectiveTime" },
        { slotName: "fenwarehouse" },
        { slotName: "waitSendNum" },
        { slotName: "QCswitch" },
        {
          slotName: "expressSwitch"
        },
        { slotName: "operation" },
      ],
      commentId: "",
      actionDialogVisible: false,
      addrId: "",
      platAdres: null,
      mcloading: false,
      urloading: false,
      storeIdsList: [],
      storeIdFlag: false,
      onlyList: [],
      fenParams: {
        addressList: [],
        command: "",
        effectiveTime: "",
        id: "",
        merchant: {}
      },
      // 立即生效
      isImmediate: null,
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    isImmediate(v) {
      if (v) {
        this.formData.effectiveTime = moment().format("YYYY-MM-DD");
      }
    },
  },
  methods: {
     //   切换页
     marxCurrentChange(val) {
      if (val) {
        this.marxpage.pageNum = val;
      } else {
        this.marxpage.pageNum = 1;
      }
      const baseRequest = {
        companyId: this.formData.matrixCompanyId || "",
        pageNum: this.marxpage.pageNum,
        pageSize: this.marxpage.pageSize,
      };
      _api
        .unionMerchantList(baseRequest)
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.marxDataList = res.data.records;
            this.marxpage.total = res.data.total;
            this.marxpage.pageNum = res.data.current;
          }
        })
    },
    seamatrix(row){
      this.marxDataList=[]
      this.formData.matrixCompanyId=row.matrixCompanyId
      this.seamatrixMiddleNum()
    },
    //查看矩阵合作商家列表
    seamatrixMiddleNum() {
      this.seaDialogVisible = true;
      this.marxCurrentChange(1);
    },
    rollClosed() {
      this.rollform.region = ''
    },
    //点击修改分转仓
    modification() {
      this.getwarehouseSelectList()
      this.rolloverDialogVisible = true
    },
    //修改分转仓
    rolloverConfirmDialog() {
      if(this.formData.warehouseId==this.rollform.region){
        return this.$message.error('修改分转仓无变化，请核实再提交');
      }
      this.formData.warehouseId = this.rollform.region
      this.formData.warehouseName = this.entrepotList.filter(item => item.id == this.rollform.region)[0].warehouseName
      this.rolloverDialogVisible = false
    },
    warChange(e) {
      console.log(e);
      this.getplantAddress()
    },
    //获取仓库
    getwarehouseSelectList() {
      _api.getwarehouseSelectList().then(res => {
        this.entrepotList = res.data
      })
    },
    // 生效时间
    addFenTimeChange() {
      this.isImmediate = !this.isImmediate;
    },
    //快递下单方式切换
    expressChange(row) {
      let yesTitle = "开启提示";
      let noTitle = "关闭提示";
      let yesTip = "是否确认开启该商户的平台自动发货功能？";
      let noTip = "是否确认关闭该商户的平台自动发货功能？";
      let yesMessage =
        "说明：启用后，该商户成交订单，发往回收商（包含靓机模式发往回收商），平台会自动呼叫快递。";
      let noMessage =
        "说明：关闭后，该商户成交订单，发往发往回收商（包含靓机模式发往回收商），平台不再自动呼叫快递。";
      const expressProps = {
        show: true,
        // 当前行信息
        currentData: row,
      };
      if (!row.openPlatformCallExpress) {
        this.expressProps = {
          ...this.expressProps,
          ...expressProps,
          title: noTitle,
          tipText: noTip,
          headerMessage: noMessage,
        }
      } else {
        this.expressProps = {
          ...this.expressProps,
          ...expressProps,
          title: yesTitle,
          tipText: yesTip,
          headerMessage: yesMessage,
        }
      }
    },

    // 快递下单确定操作
    expressConfig() {
      _api.openPlatformCallExpress({
        companyId: this.expressProps.currentData.companyId,
        openPlatformCallExpress: this.expressProps.currentData.openPlatformCallExpress
      }).then(r => {
        this.$message.success("操作成功");
        this.expressProps = {
          ...this.expressProps,
          show: false
        };
        this.handleCurrentChange();
      })
    },
    OffdeliveryClick(row) {
      this.turnonShow = true
      this.OfforderNo = row.orderNo
    },
    //
    cuClick(data) {
      console.log(data);
      this.onlyList = []
      if (data === 1) {
        this.storeIdFlag = true
      } else {
        this.storeIdFlag = false
        this.onlyList.push(data)
      }

      this.delDialogVisible = true;
    },
    //线下发货关闭
    enableClosed() {
      this.OfflineDelivery = ""
    },
    //线下发货确认
    offlineSubmit() {
      console.log(this.OfflineDelivery);
      if (!this.OfflineDelivery) {
        this.$message.error("请输入线下快递单号");
        return
      }
      _api.dptmBindExpress({
        "expressNo": this.OfflineDelivery,
        "orderNo": this.OfforderNo
      }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg || "操作成功");
          this.turnonShow = false
          this.OfflineDelivery = ""
          this.machinepage.pageNum=1
          this.machineCurrentChange()

        }
      })

    },
    //待发货旧机
    shipMachine(row) {
      this.spDialogVisible = true
      this.machineparams.middleId = row.id
      this.machinepage.pageNum=1
      this.getStoreSelectList(row.companyId)
      this.machineCurrentChange()
    },
    //查询平台商家地址
    getStoreSelectList(companyId) {
      _api.getStoreSelectList({ companyId }).then(res => {
        this.storeList = res.data
      })
    },
    //查询平台商家地址
    getplantAddress() {
      _api.queryFormAddress({ warehouseId: this.warehouseId }).then(res => {
        this.adressList = res.data
      })
    },
    //关闭选择修改地址
    cangeClosed() {
      this.adreeId = ""
      this.platAdres = {}
    },
    //修改地址
    chansdreess(data) {
      this.platAdres = data
      _api.queryFormAddress({ warehouseId: data.warehouseId }).then(res => {
        this.adressList = res.data
        this.actionDialogVisible = true
      })
    },
    //
    acConfirmDialog() {
      console.log(this.adreeId, this.adressList[this.adreeId]);
      if (this.adreeId !== "") {
        //修改仓库地址
        this.formData.addressList.forEach((item, index) => {
          if (this.platAdres.warehouseId == item.warehouseId) {
            this.formData.addressList[index] = JSON.parse(JSON.stringify(this.adressList[this.adreeId]))
          }
        })
      }
      console.log(this.formData.addressList, "this.formData.addressList");
      this.actionDialogVisible = false
    },
    //选择修改地址
    adSlect(index, data) {
      console.log(index, data);
      this.adreeId = index
    },
    fenClosed() {
      this.handleCurrentChange(this.page.pageNum);
    },
    //分转授权
    fenConfirmDialog(formName) {
      console.log(this.formData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.insideType = true
          this.enableTitle = '启用分转提示'
          this.enableShow = true
        } else {
          return false;
        }
      });
    },
    //编辑分转
    transfeAction(row) {
      this.insideType = row.isEnable;
      this.isEnableAction = false;
      this.efftimeFlag = true
      this.sublicenseDialogVisible = true
      _api.getMiddleInfo({ middleId: row.id }).then(res => {
        this.formData = res.data
      })
    },
    //启用、禁用
    switchInsideBiz(row) {
      this.waitSendNum = row.waitSendNum || 0
      this.isImmediate = false;
      this.isEnableAction = true
      this.insideType = row.isEnable
      this.enableTitle = row.isEnable ? '启用分转提示' : '禁用分转提示'
      this.addrId = row.id;
      if (!this.insideType) {
        this.enableShow = true
      } else {
        this.efftimeFlag = false
        this.sublicenseDialogVisible = true
        _api.getMiddleInfo({ middleId: row.id }).then(res => {
          this.formData = res.data
        })
      }
    },
    // 启用、禁用弹框关闭
    insideClosed() {
      this.enableShow = false
      this.command = ""
      this.handleCurrentChange(this.page.pageNum);
    },
    insideSubmit() {
      console.log(this.insideType);
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return
      }
      this.enableButLoading = true
      if (!this.insideType) {
        _api.enableMiddle({
          "addressList": this.formData.addressList.map(item => {
          return {
            "addressId": item.id,
            "warehouseId": item.warehouseId
          }
        }),
          "effectiveTime": null,
          "id": this.addrId,
          command: this.command,
          "isEnable": this.insideType,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg || "操作成功");
          }
          this.enableShow = false
          this.enableButLoading = false
        }).catch(() => {
          this.enableButLoading = false
        });
      } else {
        if (this.efftimeFlag) {
          console.log(this.formData);
          this.fenParams.merchant = []
          this.turnonSubmit()
        } else {
          this.QySubmit()
        }
      }
    },
    //启用分转
    QySubmit() {
      console.log(this.formData);
      _api.enableMiddle({
        "addressList": this.formData.addressList.map(item => {
          return {
            "addressId": item.id,
            "warehouseId": item.warehouseId
          }
        }),
        "effectiveTime": moment(this.formData.effectiveTime).format('x'),
        "id": this.addrId,
        command: this.command,
        warehouseId:this.formData.warehouseId,
        "isEnable": this.insideType,
        isImmediate: this.isImmediate
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg || "操作成功");
          this.enableShow = false
        }
        this.enableShow = false
        this.sublicenseDialogVisible = false
        this.enableButLoading = false
      }).catch(() => {
        this.enableButLoading = false
      });
    },
    //编辑分转
    turnonSubmit() {
      this.fenParams.addressList = this.formData.addressList.map(item => {
        return {
          "addressId": item.id,
          "warehouseId": item.warehouseId
        }
      })
      this.fenParams.command = this.command
      this.fenParams.id = this.formData.id
      this.fenParams.merchant={
        "merchantId": this.formData.merchantId,
        "warehouseId": this.formData.warehouseId
      }
      this.fenParams.effectiveTime = moment(this.formData.effectiveTime).format('x')
      _api.updateMiddle(this.fenParams).then(res => {
        if (res.code == 1) {
          this.$message({
            message: '编辑成功！',
            type: 'success'
          });
          this.enableShow = false
          this.sublicenseDialogVisible = false
        }
        this.enableButLoading = false
      }
      ).catch(() => {
        this.enableButLoading = false
      });
    },
    //催发货表格查询
    shipmentConfirm() {
      this.urparams.staffName = this.staffName
      this.urparams.staffPhone = this.staffPhone
      this.urparams.storeId = this.storeId
      this.UrgeCurrentChange()
    },
    // 催发货表格数据
    UrgeCurrentChange() {
      this.urloading = true;
      _api.getWaitSendGroupStore(this.urparams).then((res) => {
        if (res.code === 1) {
          this.urDataList = res.data;
          this.urloading = false;
        }
      });
    },
    // 代发货旧机表格数据
    machineCurrentChange(val) {
      if (val) {
        this.machinepage.pageNum = val;
      }
      this.machineparams.pageNum = this.machinepage.pageNum
      this.machineparams.pageSize = this.machinepage.pageSize
      this.mcloading = true;
      _api.getWaitSendOrder(this.machineparams).then((res) => {
        if (res.code === 1) {
          this.machineDataList = res.data.records;
          this.machinepage.total = res.data.total;
          this.machinepage.pageNum = res.data.current;
          this.mcloading = false;
        }
      });
    },
    //代发货旧机
    machinementConfirm(data) {
      console.log(data);
      this.machineparams.staffName = this.staffName
      this.machineparams.staffPhone = this.staffPhone
      this.machineparams.storeId = this.storeId
      this.machineparams.pageNum = 1
      this.machineparams.pageSize = 5
      _api.getWaitSendOrder(this.machineparams).then((res) => {
        if (res.code === 1) {
          this.machineDataList = res.data.records;
          this.machinepage.total = res.data.total;
          this.machinepage.pageNum = res.data.current;
          this.mcloading = false;
        }
      });
    },
    // 表格数据
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        companyName: this.SeachParams.companyName,
        effectiveStatus: this.SeachParams.effectiveStatus,
        merchantName: this.SeachParams.merchantName,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        warehouseId: this.SeachParams.warehouseId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.getMiddleList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 查看图片
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      this.DialogImgList = row.images || [];
      console.log(this.DialogImgList);
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
    },
    //分转订单
    subrevolutionClick(row) {
      const { href } = this.$router.resolve({
        path: "/transferManagement/spunGoods",
        query: {
          companyId: row.companyId,
          merchantId: row.merchantId,
          type: 0
        },
      });
      window.open(href, "_blank");
    },

    // 查看订单
    problemClick(row) {
      const { href } = this.$router.resolve({
        path: "/RecallOrder/index",
        query: {
          companyId: row.companyId,
          merchantId: row.merchantId,
        },
      });
      window.open(href, "_blank");
    },
    // 编辑问题弹框关闭
    problemClosed() {
      this.problemDialogVisible = false;
    },
    // 代发货旧机弹框关闭
    spClosed() {
      this.spDialogVisible = false;
      this.deliveryDialogVisible = false
      this.machineData = null
      this.storeId = ""
      this.staffPhone = ""
      this.staffName = ""
      this.machineparams = {
        "middleId": "",
        "pageNum": 1,
        "pageSize": 5,
        "staffName": "",
        "staffPhone": "",
        "storeId": ""
      }
      this.urparams = {
        "middleId": "",
        "pageNum": null,
        "pageSize": null,
        "staffName": "",
        "staffPhone": "",
        "storeId": ""
      }
    },
    handleUploadGroupChange(list) {
      this.formData.images = list;
    },
    handleConfirmDialog(formName) {
      console.log(this.formData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.configUpdateComment(this.formData).then((res) => {
            if (res.code === 1) {
              this.handleCurrentChange();
              this.problemDialogVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 催发货
    delClick(row) {
      this.deliveryDialogVisible = true;
      this.urparams.middleId = row.id
      this.getStoreSelectList(row.companyId)
      this.UrgeCurrentChange()
    },
    //催发货选择
    handleSelect(data) {
      console.log(data);
      this.storeIdsList = data
    },
    delConfirmDialog() {
      let redata = {
        "middleId": this.urparams.middleId,
        "storeIds": []
      }
      if (this.storeIdFlag) {
        console.log(this.storeIdsList);
        this.storeIdsList.forEach(res => {
          redata.storeIds.push(res.storeId)
        })
      } else {
        this.onlyList.forEach(res => {
          redata.storeIds.push(res.storeId)
        })
        console.log(this.onlyList);
      }
      console.log(redata);
      _api.remindShipment(redata).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg || "操作成功");
          this.delDialogVisible = false
          this.UrgeCurrentChange()
        }
      })
    },

  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  padding: 0 20px;
  background-color: #fff;

  .switcStle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /deep/.el-button {
      text-decoration: underline;
    }
  }

  .update_warehouse {
    margin-left: 100px;
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }

  .ware_hous {
    /deep/.el-form-item {
      margin-bottom: 10px;
    }
  }

  .ad_cstyle {
    width: 100%;
    padding: 15px;
    min-height: 110px;
    background: #F5F6FA;
    border-radius: 14px;
    margin-bottom: 15px;


    .adc_top {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .adc_line {
        width: 3px;
        height: 13px;
        background: #0981FF;
        border-radius: 2px;
        margin-right: 5px;
      }

      .xiugai {
        /deep/.el-button {
          text-decoration: underline;
          padding: 5px 0 5px 10px;
        }
      }

    }
  }

  .displaghf {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actionstyle {
    margin: 20px 0;
  }

  .im_actin {
    img {
      width: 15px;
      height: 15px;
    }
  }

  .center_show {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 0 20px 20px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .images-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .images-box>img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }

  .images-box>span {
    color: #0981ff;
    cursor: pointer;
  }

  .text-down {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }

  .enable-tip {
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .img-box {
    margin-bottom: 20px;

    // 图片
    .imgs-num {
      text-align: center;
      margin: 5px 0;
      font-weight: bold;
    }

    .img-tips-box {
      display: flex;
      height: 30px;
      width: max-content;
      border: 1px solid #0981ff;

      .img-tips {
        width: 80px;
        text-align: center;
        line-height: 30px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .img-tips-default {
        background: #fff;
        color: #0981ff;
      }

      .img-tips-choose {
        background: #0981ff;
        color: #fff;
      }
    }

    // 列表
  }
}

.phone_redynum {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #333333;

  span {
    color: red;
  }
}

.addFenTime {
  width: 100%;
  display: flex;
  align-items: center;

  >div:first-of-type {
    width: 60% !important;
    margin-right: 20px;
  }

  >div:last-of-type {
    flex: auto;
  }
}
/deep/.el-input{
  position: relative;
}
.biaoji_gb{
  display: inline-block;
  margin-left: 20px;
}

.raido_inner {
  display: flex;
  align-items: center;
  cursor: pointer;

  >div {
    border: 1px solid #0981FF;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }

  >p {
    font-size: 14px;
    margin-left: 10px;
  }

  .active_select {
    border-color: #0981FF;
  }

  .active_select::after {
    background: #0981FF;
  }
}
</style>
